import React from "react";

function LeadContact() {
  const date = new Date().getFullYear();
  return (
    <section className="section">
      <div className="img-anim">
        <div className="img-anim-bg" />
        <img src={`/assets/images/danang-night.webp`} alt="Da Nang" />
      </div>
      <div className="section-lead">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h3 className="lead-title anim-bot mb-4 mb-lg-0">
                <span className="text-muted">{date}.</span>
                <br />
                Meet the Team
              </h3>
            </div>
            <div className="col-12 col-lg-8">
              <div className="lead-content anim-bot vw-pb-3">
                <div className="lead-text">
                  We are a small team of Designers, Coders &amp; Dreamers —{" "}
                  <br /> excited to help you bring your ideas to life.
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-sm-5 col-lg-4">
                  <div className="team-member vw-py-3 anim-bot">
                    <img
                      src={`assets/images/toan.webp`}
                      alt="Toan Nguyen Dinh"
                    />
                    <h4 className="lead-subtitle">Toan Nguyen Dinh</h4>
                    <p className="lead-small text-muted">
                      Co-Founder, Lead Full-Stack Developer
                    </p>
                  </div>
                </div>

                <div className="col-6 col-sm-5 col-lg-4 offset-sm-1">
                  <div className="team-member vw-py-3 anim-bot">
                    <img
                      src={`assets/images/bekzat.webp`}
                      alt="Bekzat Sadykov"
                    />
                    <h4 className="lead-subtitle">Bekzat Sadykov</h4>
                    <p className="lead-small text-muted">
                      Co-Founder, Design Lead
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-sm-5 col-lg-4">
                  <div className="team-member vw-py-3 anim-bot">
                    <img src={`assets/images/vy.webp`} alt="Ha Vy" />
                    <h4 className="lead-subtitle">Ha Vy</h4>
                    <p className="lead-small text-muted">
                      Frontend and Mobile App Developer
                    </p>
                  </div>
                </div>

                <div className="col-6 col-sm-5 col-lg-4 offset-sm-1">
                  <div className="team-member vw-py-3 anim-bot">
                    <img
                      src={`assets/images/tien_ngo_le.jpg`}
                      alt="Tien Ngo Le"
                    />
                    <h4 className="lead-subtitle">Tien Ngo Le</h4>
                    <p className="lead-small text-muted">
                      Frontend and Mobile App Developer
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-sm-5 col-lg-4">
                  <div className="team-member vw-py-3 anim-bot">
                    <img src={`assets/images/olga.webp`} alt="Olga Khan" />
                    <h4 className="lead-subtitle">Olga Khan</h4>
                    <p className="lead-small text-muted">UX/UI Designer</p>
                  </div>
                </div>
                <div className="col-6 col-sm-5 col-lg-4 offset-sm-1">
                  <div className="team-member vw-py-3 anim-bot">
                    <img src={`assets/images/huy.jpeg`} alt="Huy Le" />
                    <h4 className="lead-subtitle">Huy Le</h4>
                    <p className="lead-small text-muted">
                      Senior Backend Developer
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-5 col-lg-4">
                  <div className="team-member vw-py-3 anim-bot">
                    <img src={`assets/images/tin.webp`} alt="Cao Tin" />
                    <h4 className="lead-subtitle">Cao Tin</h4>
                    <p className="lead-small text-muted">
                      Frontend and Mobile App Developer
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-5 col-lg-4  offset-sm-1">
                  <div className="team-member vw-py-3 anim-bot">
                    <img src={`assets/images/ha.jpeg`} alt="Thu Ha" />
                    <h4 className="lead-subtitle">Thu Ha</h4>
                    <p className="lead-small text-muted">
                      Frontend and Mobile App Developer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeadContact;
