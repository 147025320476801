import React from 'react'
import {createRoot} from 'react-dom/client'
import WithRouterApp from './App'
import {BrowserRouter as Router} from 'react-router-dom'

import './index.css'
import {ThemeContextProvider} from './context/themeContext'

const rootElement = document.getElementById('root');
createRoot(rootElement).render(
  <ThemeContextProvider>
    <Router>
      <WithRouterApp />
    </Router>
  </ThemeContextProvider>,
  document.getElementById('root')
)
